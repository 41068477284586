import React, {useState, useEffect} from 'react'
import {
	Container,
	Header,
	Loader,
	Grid
} from 'semantic-ui-react'
import Seo from '../../components/SEO'
import Layout from '../../components/Layout'
import * as styles from '../styles/pages.module.css'

import MyAccountHeader from '../../components/Pages/MyAccount/header'

import Profile from '../../components/Pages/MyAccount/profile'

const Negosyo = require('../../../lib/negosyo')

const DEFAULTCONTACT={contact:"", validated: false};

const MyAccountContactPage = ({location}) => {
	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState("");

	const [user, setUser] = useState(undefined);
	const [details, setDetails] = useState({"name":undefined});

	const [primaryemail, setPrimaryemail] = useState(DEFAULTCONTACT);
	const [primarymobile, setPrimarymobile] = useState(DEFAULTCONTACT);


	useEffect(() => {
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data!==false) {
				setMessage("");
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					setUser(user);
					Negosyo.getUserProfile(user.username).then((response)=>{
						if(response.status==="OK") {
							setDetails(response.data);
							Negosyo.getContactList(user.username).then((contactresponse)=> {
								if(contactresponse.status==="OK") {
									setPrimaryContact(contactresponse.data);
								}else {
									setPrimaryContact([]);
								}
								setLoading(false);
							}).catch((err)=>{
								setPrimaryContact([]);
								setLoading(false);
								setMessage("Error occurred. Please reload page to try again");
							});
						}
					}).catch((err)=>{
						//console.log(err)
						setLoading(false);
						setMessage("Error occurred. Please reload page to try again");
					});
				}).catch((err)=>{
					setLoading(false);
					setMessage("Error occurred. Please reload page to try again");
				});
			}
		})

	}, [])

	function setPrimaryContact(contactlist) {
		var tempemail = DEFAULTCONTACT;
		var tempcontact = DEFAULTCONTACT;

		var idx = 0;
		while (idx < contactlist.length) {
			if (contactlist[idx].contact.indexOf("@") >= 0) {
				if (tempemail.contact === "") {
					tempemail = contactlist[idx];
				}
			} else {
				if (tempcontact.contact === "") {
					tempcontact = contactlist[idx];
				}
			}
			idx++;
		}
		setPrimaryemail(tempemail);
		setPrimarymobile(tempcontact);
	}


	async function editProfile(newAttributes) {
		try {
			const response = await Negosyo.editUserProfile(user.username, newAttributes)
			if( response.status==="OK" ) {
				setDetails(response.data);
				return true;
			}else {
				return false;
			}
		}catch(e) {
			return false;
		}

	}

	return (
		<Layout location={location}>
			<Seo title={"Manage Contacts"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<MyAccountHeader location={location} />
				<Container className={styles.container}>
					<Header className={styles.superHeader}>Account Information</Header>
					<>
						{loading === false ?
							<>
								<Profile
									profile={details}
									editProfile={editProfile}
								/>
								<Grid>
									<Grid.Row className={styles.profileGridRow}>
										<Grid.Column width={8} >
											<Header className={styles.header}>
												Mobile:
											</Header>
											<span>{primarymobile.contact}</span>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row className={styles.profileGridRow}>
										<Grid.Column width={8} >
											<Header className={styles.header}>
												email:
											</Header>
											<span>{primaryemail.contact}</span>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</>
						:
							<div className={styles.tempDiv}>
								<Loader
									inline="centered"
									active
									className={styles.loaderMyAccount}
								/>
							</div>
						}
					</>
					{message.length > 0 &&
						<p className={styles.text}>{message}</p>
					}

				</Container>
			</Container>
		</Layout>
	)
}

export default MyAccountContactPage

