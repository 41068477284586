import React, {Component} from "react"
import {
	Header,
	Grid,
	Button,
	Form,
	Message,
	//Divider
} from 'semantic-ui-react'
import * as styles from '../styles/pages.module.css'

import {
	backColor,
	confirmationColor,
	//secondaryButtonColor
} from '../../../globalVar'

const initialStateError ={
	name:undefined
}

export default class Profile extends Component {
	state = {
		name:"",
		editMode:false,
		loading:false,
		message:undefined,
		error: {
			...initialStateError
		}
	}


	handleInputChange = (e, { name, value }) => {
		this.setState({ [name]: value })
		let isRequired = false
		if( name==="name" ) {
			isRequired = true;
		}
		if(	value==='' && isRequired === true ){
			const message = " must not be blank"
			this.setState(prevState => ({
				error: {
					...prevState.error,
					[name] : message
				}
			}))
		} else {
			const message = null
			this.setState(prevState => ({
				error: {
					...prevState.error,
					[name] : message
				}
			}))
		}
	}

	validateInput = (state) => {
		let valid = true;
		for( let key of Object.keys(state) ) {
			if(state[key]==="" || state[key] ===undefined ) {
				valid=false;
				this.setState(prevState => ({
					error: {
						...prevState.error,
						[key] : " must not be blank"
					}
				}))
			}
		}
		return valid;
	}


	cleanInput = () =>{

	}

	editProfile = () => {

		let {
			name,
		} = this.state
		name = name.trim(); //should be moved to cleanInput
		this.setState({name:name})
		const valid = this.validateInput({name})
		if(valid) {
			this.setState({loading:true})
			const newProfile = {
				name:name
			}
			const {
				editProfile
			} = this.props

			editProfile(newProfile)
			.then((returnValue)=>{
				if(returnValue) {
					this.setState({editMode:false, loading:false})
				}else {
					this.setState({loading:false, message:"Failed to Edit Profile"}, ()=>{
						setTimeout(()=>{
							this.setState({
								message:undefined
							})
						}, 5000)
					})
				}
			})

		}
	}

	render() {
		const {
			editMode,
			name,
			error,
			loading,
			message
		}=this.state
		const {
			editProfile,
			handleInputChange
		} = this
		const {
			profile,
		} = this.props
		return(
			<React.Fragment>
				<Grid>
					<Grid.Row className={styles.profileGridRow}>
						<Grid.Column width={8} >
							<Header className={styles.header}>
								Name:
							</Header>
							{editMode===false ? (
								<span>{profile.name ? profile.name:"Please enter your name"}</span>
							):(
								<Form>
									<Form.Input
										value={name}
										name={"name"}
										error={error.name}
										onChange={handleInputChange}
									/>
								</Form>
							)}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className={styles.profileGridRow}>
						<Grid.Column width={16}>
							{editMode===false ? (
								<Button
									content={"Edit Profile"}
									color={confirmationColor}
									onClick={()=> {
										this.setState({
											editMode:true,
											name:profile.name
										})
									}}
								/>
							):(
								<React.Fragment>
									{message && (
										<Grid.Column width={16}>

											<Message negative className={styles.msg}>
												<Message.Header>
													{message}
												</Message.Header>

											</Message>
										</Grid.Column>
									)}
									<Button
										content={"Back"}
										color={backColor}
										onClick={()=> {
											this.setState({
												editMode:false,
												error:{...initialStateError}
											})
										}}
										disabled={loading}
										className={styles.form}
									/>
									<Button
										content={"Confirm Edit"}
										color={confirmationColor}
										onClick={editProfile}
										disabled={loading}
										loading={loading}
										className={styles.mainButton}
									/>
								</React.Fragment>
							)}

						</Grid.Column>
					</Grid.Row>
				</Grid>
			</React.Fragment>
		)
	}

}

